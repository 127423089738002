import React from "react"
import Link from "gatsby-link"
import BlogLayout from '../components/BlogLayout'
import Seo from "../components/SEO"
import { graphql } from "gatsby"

// Create pageData object to simulate markdownRemark object
const pageData = {
  frontmatter: {
    title: "Blog",
    description: "Blog of Muhammad Iqbal C. Rochman",
  },
}

export default function BlogIndex({ data }) {
  const posts = data.allMarkdownRemark.edges;

  return (
    <BlogLayout>
      <div>
        { posts.map(({ node }) => {
          const { excerpt, frontmatter } = node;
          const { title, date, path } = frontmatter;

          return (
            <div className="blog-post">
              <Seo postData={pageData} />
              <h1>
                <Link to={path}>
                  {title}
                </Link>
              </h1>
              <small>{date}</small>
              <p
                className="blog-post-content"
                dangerouslySetInnerHTML={{ __html: excerpt }}
              />
            </div>
          )
        })}
      </div>
    </BlogLayout>
  )
}

export const pageQuery = graphql`
  query AllBlogPost {
    allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC}) {
      edges {
        node {
          excerpt(pruneLength: 280)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            path
          }
        }
      }
    }
  }
`
